:root {
  --bg-set1-1: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666ee5a151ada6549e6785f4_Danelec_MeshGradient_Orange-Neutral_2880x1620_1.webp");
  --bg-set1-2: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666ee5a10e91150b5dd61709_Danelec_MeshGradient_Orange-Neutral_2880x1620_10.webp");
  --bg-set1-3: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666ee5a15ad2aea40e1bfe13_Danelec_MeshGradient_Orange-Neutral_2880x1620_3.webp");
  --bg-set1-4: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666ee5a1b49442553d085a6b_Danelec_MeshGradient_Orange-Neutral_2880x1620_4.webp");
  --bg-set1-5: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666ee5a1aa74b589511dc720_Danelec_MeshGradient_Orange-Neutral_2880x1620_5.webp");
  --bg-set2-1: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a766faf01ec39eedb61_Danelec_MeshGradient_Neutral-Neutral_2880x1620_1-min.jpg");
  --bg-set2-2: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a76a56155585ce2c492_Danelec_MeshGradient_Neutral-Neutral_2880x1620_10-min.jpg");
  --bg-set2-3: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a766352122ba1787629_Danelec_MeshGradient_Neutral-Neutral_2880x1620_3-min.jpg");
  --bg-set2-4: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a772b35e0d750d94604_Danelec_MeshGradient_Neutral-Neutral_2880x1620_4-min.jpg");
  --bg-set2-5: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a755b2cda34f6aa3bda_Danelec_MeshGradient_Neutral-Neutral_2880x1620_6-min.jpg");
  --bg-set3-1: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a310c02176d2af31f62_Danelec_MeshGradient_Orange-Red_2880x1620_16-min.jpg");
  --bg-set3-2: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a312becf766090e91da_Danelec_MeshGradient_Orange-Red_2880x1620_15-min.jpg");
  --bg-set3-3: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a31c0c47e0b141f89d7_Danelec_MeshGradient_Orange-Red_2880x1620_10-min.jpg");
  --bg-set3-4: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a3100ceda9e2ab9ef80_Danelec_MeshGradient_Orange-Red_2880x1620_12-min.jpg");
  --bg-set3-5: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a30ea1acc1645a56d0b_Danelec_MeshGradient_Orange-Red_2880x1620_2-min.jpg");
  --bg-set4-1: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a5e54c790cc571d19a7_Danelec_MeshGradient_Neutral-Red_2880x1620_15-min.jpg");
  --bg-set4-2: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a5d6352122ba1786446_Danelec_MeshGradient_Neutral-Red_2880x1620_12-min.jpg");
  --bg-set4-3: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a5c082d44e307f1cd7d_Danelec_MeshGradient_Neutral-Red_2880x1620_10-min.jpg");
  --bg-set4-4: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a5da27393f8161bf0f8_Danelec_MeshGradient_Neutral-Red_2880x1620_9-min.jpg");
  --bg-set4-5: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666f0a5c6faf01ec39eec92f_Danelec_MeshGradient_Neutral-Red_2880x1620_1-min.jpg");
  --bg-noise: url("https://cdn.prod.website-files.com/660d3cdafac9c4d8c27957a8/666df6bea1e21cd95a7f6a62_Noise%201920.png");
}

.global_gradient {
  /* This will be fully covered by images below, 
  but if there's an issue with the CDN this will be the fall-back */
  background-image: url("../assets/patterns/orange-red-gradient-15.jpg");
}

.global_gradient,
.global_gradient-image {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0%;
}

@keyframes fadeInOut2 {
  0% {
    opacity: 0;
  }
  20%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut3 {
  0%,
  20% {
    opacity: 0;
  }
  40%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut4 {
  0%,
  40% {
    opacity: 0;
  }
  60%,
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInOut5 {
  0%,
  60% {
    opacity: 0;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.global_gradient-image.is-1 {
  z-index: 1;
  opacity: 1;
}

.global_gradient-image.is-2 {
  z-index: 2;
  animation: fadeInOut2 8s linear infinite forwards;
}

.global_gradient-image.is-3 {
  z-index: 3;
  animation: fadeInOut3 8s linear infinite forwards;
}

.global_gradient-image.is-4 {
  z-index: 4;
  animation: fadeInOut4 8s linear infinite forwards;
}

.global_gradient-image.is-5 {
  z-index: 5;
  animation: fadeInOut5 8s linear infinite forwards;
}

.global_gradient[data-set="set1"] .global_gradient-image.is-1 {
  background-image: var(--bg-set1-1);
}

.global_gradient[data-set="set1"] .global_gradient-image.is-2 {
  background-image: var(--bg-set1-2);
}

.global_gradient[data-set="set1"] .global_gradient-image.is-3 {
  background-image: var(--bg-set1-3);
}

.global_gradient[data-set="set1"] .global_gradient-image.is-4 {
  background-image: var(--bg-set1-4);
}

.global_gradient[data-set="set1"] .global_gradient-image.is-5 {
  background-image: var(--bg-set1-5);
}

.global_gradient[data-set="set2"] .global_gradient-image.is-1 {
  background-image: var(--bg-set2-1);
}

.global_gradient[data-set="set2"] .global_gradient-image.is-2 {
  background-image: var(--bg-set2-2);
}

.global_gradient[data-set="set2"] .global_gradient-image.is-3 {
  background-image: var(--bg-set2-3);
}

.global_gradient[data-set="set2"] .global_gradient-image.is-4 {
  background-image: var(--bg-set2-4);
}

.global_gradient[data-set="set2"] .global_gradient-image.is-5 {
  background-image: var(--bg-set2-5);
}

.global_gradient[data-set="set3"] .global_gradient-image.is-1 {
  background-image: var(--bg-set3-1);
}

.global_gradient[data-set="set3"] .global_gradient-image.is-2 {
  background-image: var(--bg-set3-2);
}

.global_gradient[data-set="set3"] .global_gradient-image.is-3 {
  background-image: var(--bg-set3-3);
}

.global_gradient[data-set="set3"] .global_gradient-image.is-4 {
  background-image: var(--bg-set3-4);
}

.global_gradient[data-set="set3"] .global_gradient-image.is-5 {
  background-image: var(--bg-set3-5);
}

.global_gradient[data-set="set4"] .global_gradient-image.is-1 {
  background-image: var(--bg-set4-1);
}

.global_gradient[data-set="set4"] .global_gradient-image.is-2 {
  background-image: var(--bg-set4-2);
}

.global_gradient[data-set="set4"] .global_gradient-image.is-3 {
  background-image: var(--bg-set4-3);
}

.global_gradient[data-set="set4"] .global_gradient-image.is-4 {
  background-image: var(--bg-set4-4);
}

.global_gradient[data-set="set4"] .global_gradient-image.is-5 {
  background-image: var(--bg-set4-5);
}

.is-grain::after {
  z-index: 6;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: var(--bg-noise);
  background-repeat: repeat;
  animation: noiseAnimation 0.5s steps(5) infinite;
  opacity: 0.038;
}

@media (prefers-reduced-motion: reduce) {
  .global_gradient-image.is-2,
  .global_gradient-image.is-3,
  .global_gradient-image.is-4,
  .global_gradient-image.is-5 {
    animation: none;
  }
}
