body {
  background-color: #331714; /* danelec red */
}

body {
  font-family: Helvetica, sans-serif;
}

.login-container {
  z-index: 10;
  height: 100%;
  min-height: 750px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.login-form {
  font-size: 14px;
  line-height: 20px;
  width: 400px;
  margin-top: 7%;
  padding-top: 4%;
  padding-bottom: 60px;
  display: flex;
  padding-left: 60px;
  padding-right: 60px;
  justify-content: center;
  flex-direction: column;
  background-color: white;
}

.login-form .label {
  margin: 5px 0;
  font-weight: normal;
  font-size: 13px;
}

.logo {
  margin: 40px 0 0 40px;
  align-self: flex-start;
}

.login-form .label:first-child {
  margin-top: 60px;
}

.login-form input {
  font-size: 12px;
  margin-bottom: 10px;
  border-radius: 2px;
  background-color: #fafafa;
  height: 35px;
}

.login-form .error {
  color: white;
  margin-top: 10px;
  font-size: 10px;
}

.login-form button {
  width: 280px;
  flex-shrink: 0;
  height: 35px;
  margin-top: 10px;
  box-shadow: none !important;
  color: white !important;
  background-color: #992f19 !important; /* danelec orange 120 */
  background-image: none !important;
  border: none;
  border-radius: 3px !important;
  font-size: 13px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #992f19 !important; /* danelec orange 120 */
  opacity: 0.9;
}

.login-form p {
  font-size: 16px;
  padding-bottom: 20px;
}

.login-form .submit-button:hover {
  background-color: #4f72f7;
  opacity: 0.8;
}

.login-form .forgot-password {
  cursor: pointer;
  color: #4f72f7;
  visibility: hidden;
}

.login-container .footer {
  margin-top: 50px;
}

.login-container .footer a {
  margin: 0;
  color: inherit;
  text-decoration: underline;
}

.login-container footer {
  margin: 30px;
  font-size: smaller;
  color: white;
}

.login-container footer a,
.login-container footer .item {
  margin: 10px;
}

.login-container footer a,
.login-container .footer {
  color: white;
}

.mail-sent-icon {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
}

.center-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}
